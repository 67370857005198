@import '~@bottomless/common/style/common';

.toasts {
  position: fixed;
  bottom: 0;
  right: 0;
  width: auto;
  padding-left: 0;
  z-index: 99999999;
  pointer-events: none;
  max-width: 350px;

  .toast {
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    border-radius: 4px !important;
    background: white !important;
    color: $primary !important;
    margin-bottom: 0.5rem !important;
    border: thin solid $primary !important;

    &-body {
      padding: 0.5rem;
    }

    &-success {
      border: thin solid rgba(40, 167, 69, 0.8) !important;
      color: rgba(40, 167, 69, 0.8) !important;
    }

    &-error {
      border: thin solid rgba(220, 53, 69, 0.8) !important;
      color: rgba(220, 53, 69, 0.8) !important;
    }
  }
}

.page-simple .toasts {
  padding-left: 0;
}

@include media-breakpoint-up(md) {
  .toasts {
    top: 0;
    bottom: initial;
    width: 350px;

    .toast {
      margin-bottom: initial;

      &-body {
        padding: 0.5rem;
      }
    }
  }

  .page-simple .toasts {
    top: 50px;
  }

  .toasts.toasts--bottom,
  .panel-admin .toasts {
    top: initial;
    bottom: 0;
    left: 5px;
    padding: 0;
  }
}

@include media-breakpoint-down(sm) {
  .toasts {
    padding-left: 0;
    width: 100%;
    bottom: 50px;

    .toast:last-child {
      margin-bottom: 55px !important;
    }
  }

  .page-simple .toasts .toast:last-child {
    margin-bottom: 0.5rem;
  }
}
