@import '../../../style/common';

.information-badge {
  @include font-size-rem(0.5);
  width: 12px;
  height: 12px;
  line-height: 14px;
  text-align: center;
  font-weight: 400;
  border-radius: 50%;
  background: $secondary;
  color: $white;

  &-lg {
    @include font-size-rem(0.68);
    width: 16px;
    height: 16px;
    line-height: 18px;
  }
}
