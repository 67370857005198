@import '../../../style/common';

a.box-link {
  color: $body-color;
  text-decoration: none;

  &:hover {
    color: $body-color;
    text-decoration: none;
  }

  & ~ a.box-link,
  & ~ .box {
    margin-top: 1rem;
  }
}

.box {
  & ~ a.box-link,
  & ~ .box {
    margin-top: 1rem;
  }

  &.box-with-shadow {
    box-shadow: $card-lg-shadow;
  }

  &-lg {
    padding: 2.5rem;
  }

  &-sm {
    padding: 0.75rem;
  }
}
