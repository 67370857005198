@import '~@bottomless/common/style/common';

.perma-alerts {
  &-button {
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 800;
  }

  &-badge {
    position: absolute;
    background: $green;
    color: $white;
    top: -6px;
    right: -6px;
    min-width: 18px;
    height: 18px;
    display: flex;
    border-radius: 50%;
    font-size: 0.7rem;
    align-items: center;
    justify-content: center;
  }
}
