@import '~@bottomless/common/style/common';

.status {
  &--active {
    color: $success;
  }

  &--draft {
    color: $primary;
  }

  &--archived {
    color: $danger;
  }

  &--hidden {
    color: $purple;
  }
}


