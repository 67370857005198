@import '~@bottomless/common/style/common';

.page-admin-gifts {
  .gift-badges {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .badges {
      :not(:first-child) {
        margin-left: 5px;
      }
    }

    .edit .btn {
      padding: 2px 20px;
    }
  }

  .title {
    text-decoration: underline;
  }

  .user-info-row {
    background-color: #f0f0f0;
    margin: 10px 0 0 0;
    padding: 10px 5px 10px 5px;
  }
}

.page-gifts {
  .gift-wrap-description {
    width: 90%;
  }

  .one-line-address-error {
    margin-top: -15px;
    margin-bottom: 15px;
  }

  .recipient-name,
  .buyer-name {
    display: flex;
    flex-direction: row;

    div {
      width: 50%;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .scale-details {
    display: flex;
    flex-direction: row;

    div {
      width: 26%;

      label {
        font-size: 0.9rem;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .buyer-address {
    .row {
      height: 55px;
    }
  }

  @include media-breakpoint-down(sm) {
    .scale-details {
      div {
        width: 100%;
      }
    }
  }
}
