$font-family-sans-serif: 'Poppins', sans-serif;
$headings-font-weight: 700;

$label-margin-bottom: 0.25rem;

$badge-font-weight: 400;
$badge-padding-y: 0.275rem;
$badge-padding-x: 0.5rem;
$badge-pill-padding-x: 0.6rem;

$body-color: #232323;
$primary: #0632c2;
$primary-accent: #e4f5ff;
$success: #3a8254;
$danger: #c33c54;
$warning: #ffd600;
$footer-color: #161616;

$card-lg-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25);

$row-spaced-padding: 30px;

$gray-on-white-wcag: #959595;
