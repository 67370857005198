.create-customer-note {
  form {
    position: relative;
  }

  textarea {
    min-height: 60px;
    max-height: 60px;
  }

  button[type='submit'] {
    position: absolute;
    top: 26px;
    right: 0;
    height: 60px;
  }
}

.customer-note {
  &.alert-dismissible .close {
    height: 100%;
  }
}

.note {
  overflow-x: auto;
  width: 630px;
}
