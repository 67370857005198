@mixin letter-spacing-rem($sizeInRem) {
  letter-spacing: #{strip-unit($sizeInRem) * 16 * -0.03}px;
}

@mixin font-size-rem($sizeInRem) {
  font-size: #{strip-unit($sizeInRem)}rem;
  @include letter-spacing-rem($sizeInRem);
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
