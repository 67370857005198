.page-fulfillment {
  .fulfillment-switch .form-group {
    margin-bottom: 0;
  }
}

.page-summary {
  .separator {
    width: 4px;
    height: 4px;
    background: #ccc;
    border-radius: 50%;
    display: inline-block;
    margin: 3px 0.5rem;
  }

  .rotation-product-name {
    font-size: 0.9rem;

    ~ .text-sm {
      font-size: 0.8rem;
      max-width: 220px;
    }
  }
}

.page-sent-to-roaster {
  .separator {
    width: 4px;
    height: 4px;
    background: #ccc;
    border-radius: 50%;
    display: inline-block;
    margin: 3px 0.5rem;
  }

  .rotation-product-name {
    font-size: 0.9rem;

    ~ .text-sm {
      font-size: 0.8rem;
      max-width: 220px;
    }
  }
  .vendor-info {
    display: flex;
    flex-direction: column;
  }
  .num-orders {
    margin-left: auto;
  }

  .order-late {
    border-radius: 1px;
    border-color: red;
  }
}
