.payout-input {
  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    min-width: 70px;
    max-width: 80px;
  }
}

.payout-field {
  .form-control {
    min-width: 70px;
    max-width: 90px;
  }
}

.size-and-shipping {
  color: #11b911;
}

.shipping-free-paid {
  font-size: 0.8rem;
}
