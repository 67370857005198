@import '~@bottomless/common/style/common';

.pricing-header {
  width: 110px;
}

.rotation-field {
  width: 80px;
  margin-right: 40px;
}

.rotating-label {
  margin-left: 40px;
}
