@import '../../../style/common';

.radio-box {
  padding-left: 0;

  .form-check-label {
    display: block;
    cursor: pointer;
    padding: $card-spacer-y $card-spacer-x;
    border: $card-border-width solid $card-border-color;
    border-radius: $card-border-radius;

    &.label-checked {
      border-color: $dark;
    }
  }

  &-sm {
    .form-check-label {
      padding: ($card-spacer-y * 0.75) ($card-spacer-x * 0.5);
    }

    .radio-box-label {
      margin-left: 0.5rem !important;
    }

    svg {
      height: 15px;
      width: 15px;
    }

    h6 {
      @include font-size-rem(0.8);
      text-transform: capitalize !important;
    }
  }
}
