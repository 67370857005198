.packaging-summary-tab {
  .button-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  .button-header {
    padding: 0.75rem 0 0 0;
  }

  .button-cell {
    padding: 0.75rem 0 0 0;

    > button {
      margin-bottom: 0.3rem;
    }
  }

  .summary-table {
    th {
      font-weight: 600;
      vertical-align: middle !important;
    }
    
    td {
      vertical-align: middle !important;
    }

    .size .row {
      justify-content: center;
    }
  }
}
