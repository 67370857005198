.loader {
  &-full-page {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
