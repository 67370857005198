@import '~@bottomless/common/style/common';

.page-ordergen {
  div[class^='SwipeableListItem_swipeableListItem'] {
    div[data-testid='swipe-left-content'] {
      background: $success;
      color: $white;
    }
  }

  .product-width {
    max-width: 25%;
  }

  &-details {
    .ordergen-user {
      display: block !important;
    }
  }

  .outline {
    outline: 2px solid $gray-300;
  }

  .outline-danger {
    outline-color: $danger;
  }

  .outline-primary {
    outline-color: $primary;
  }

  .outline-success {
    outline-color: $success;
  }

  .rotation-product-grind {
    padding: 0.2em 0.4em;
    font-size: 0.75rem;
    background: $success;
    color: $white;
    white-space: nowrap;
  }

  .separator {
    width: 4px;
    height: 4px;
    background: $secondary;
    border-radius: 50%;
    display: inline-block;
    margin: 3px 0.5rem;
  }
}

.disabled-btn {
  cursor: not-allowed !important;
}

.disabled-link {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  cursor: not-allowed !important;
}

.membership-refund {
  button {
    width: fit-content;
  }

  p {
    font-size: 14px;
    color: grey;
    margin-right: 5px;
    margin-bottom: 0;
  }
}

.membership-action {
  display: flex;
  justify-content: center;
  margin-top: -1px;

  input {
    margin-right: 0;
  }
}

.order-refund-modal {
  width: 500px;
}

.membership-refund-modal {
  width: 500px;
}

.refunded-text {
  color: #28a745;
  margin-bottom: 0;
}
