.shopify-configuration-modal {
  .row-margin-reset {
    margin-left: 0;
    margin-bottom: 0.25rem;
    margin-right: 1rem;
  }

  .invalid-feedback {
    display: block;
  }
}
