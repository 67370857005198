.form-group--date {
  label {
    padding-right: 0.5rem;
  }
}

.form-group--date-invalid {
  .invalid-feedback {
    display: block;
  }
}
