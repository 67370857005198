.panel-content {
  h2 {
    font-size: 700;
  }
}
.panel-wrapper {
  .tabs {
    overflow: auto;
  }
}
