@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'functions';
@import 'variables';
@import 'mixins';

@import '~bootstrap/scss/bootstrap.scss';

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  @include letter-spacing-rem(1);
}

a:not(.btn),
.btn-link {
  color: $secondary;
  text-decoration: underline;
}

button:not(.btn-link) {
  text-transform: capitalize;
}

label {
  @include font-size-rem(0.8);
  font-weight: 600;
}

.form-check-input {
  margin-top: 0.2rem;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.form-group-select {
  position: relative;

  &:after {
    position: absolute;
    right: 0.75rem;
    top: 0rem;
    display: block;
    font-family: 'ForkAwesome';
    content: '\f0dd';
    color: $input-border-color;
  }

  &.form-group--with-label:after {
    top: 1.7rem;
  }
}

.custom-control-label {
  line-height: 1.5rem;
}

select:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-transform: uppercase;
}

h1,
.h1 {
  @include letter-spacing-rem($h1-font-size);
}

h2,
.h2 {
  @include letter-spacing-rem($h2-font-size);
}

h3,
.h3 {
  @include letter-spacing-rem($h3-font-size);
}

h4,
.h4 {
  @include letter-spacing-rem($h4-font-size);
}

h5,
.h5 {
  @include letter-spacing-rem($h5-font-size);
}

h6,
.h6 {
  @include letter-spacing-rem($h6-font-size);
}

.stroked-heading {
  color: #000;
  -webkit-text-stroke: 3px #000;
  -webkit-text-fill-color: #fff;
  font-style: italic;
  @include font-size-rem(2.5);
  text-shadow: 5px 3px #ced4da;
}

.spin {
  animation: spin 2s infinite linear;
}

.btn-outline-primary {
  background-color: $primary-accent;
}

.nav-tabs {
  border-bottom: thin solid $card-border-color;

  .nav-link {
    @include font-size-rem(1);
    padding: 1rem;
    font-weight: 500;
    color: $secondary;
    cursor: pointer;

    &:hover {
      border-color: transparent;
    }

    &.active {
      background: transparent;
      border-color: transparent transparent $primary transparent;
      color: $primary;
    }
  }
}

.text-strikethrough {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.small {
  @include font-size-rem(0.8);
}

.extra-small {
  @include font-size-rem(0.625);
}

.font-weight-semi-bold {
  font-weight: 600;
}

.form-inline .form-group--date label {
  @include font-size-rem(1);
  margin-right: 0.5rem;
}

.text-strikethrough {
  text-decoration: line-through;
}

.StripeElement:not(.PaymentRequestButton) {
  border: thin solid $input-border-color;
  border-radius: 0.25rem;
  padding: 0.8rem 0.75rem 0.6rem;
  background: $input-bg;
  margin-bottom: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  line-height: 1.5;

  &.is-invalid {
    border-color: $danger;
  }
}

.StripeElement--focus {
  border-color: $primary !important;
}

.StripeElement.PaymentRequestButton {
  width: 18.75rem;
}

.autocomplete-dropdown {
  margin-top: -1rem;
  margin-bottom: 1rem;
  width: 100%;
  overflow: visible;
  position: relative;
  z-index: 1000;
  height: 1px;

  .autocomplete-inner {
    border-left: 1px solid $input-border-color;
    border-right: 1px solid $input-border-color;
    border-bottom: 1px solid $input-border-color;

    > div {
      background: $white;
      padding: 0.3rem 0.5rem;
      cursor: pointer;
      font-size: 0.9rem;
    }
  }
}

.flex-gap-1 {
  gap: 1rem;
}

@include media-breakpoint-down(sm) {
  .modal-dialog {
    height: 100%;
    max-width: none;
    margin: 0;

    .modal-content {
      height: 100%;
      border: none;
      border-radius: none;
    }

    .modal-body {
      overflow: scroll;
    }
  }

  .StripeElement.PaymentRequestButton {
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .row-spaced {
    margin-left: -$row-spaced-padding;
    margin-right: -$row-spaced-padding;

    @for $i from 1 through 12 {
      > .col-#{$i} {
        padding-left: $row-spaced-padding;
        padding-right: $row-spaced-padding;
      }
    }
  }
}
