@import '~@bottomless/common/style/common';

.panel {
  &-layout {
    display: flex;
    height: 100vh;
  }

  &-nav {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray-300;

    .shopping {
      margin-right: 0.3rem;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.7rem;
      padding: 0.7rem 1.2rem;
      margin-top: 0.7rem;
      font-weight: 500;
      color: $gray-500;
      transition: color 0.2s ease-in-out;

      &.active,
      &:hover {
        color: $gray-900;
        transition: color 0.2s ease-in-out;
      }

      &,
      &:hover {
        text-decoration: none;
      }
    }
  }

  &-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background: $gray-100;
    padding: 0.3rem 0 0;

    .panel-title {
      min-width: 16.66667%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      font-family: 'Poppins', sans-serif;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
  }
}

.panel-admin {
  .panel-title {
    margin-right: 2rem;
    font-size: 1.5rem;
  }
}

.toasts {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 80px;

  .toast {
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    border-radius: 0;
    border: none;
    background: rgba(0, 0, 0, 0.5);
    color: $white;
    margin-bottom: 0.5rem;

    &-body {
      padding: 0.5rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .toasts {
    top: 0;
    bottom: initial;
    padding-right: 1rem;
    padding-top: 1rem;

    .toast {
      width: 350px;
      margin-bottom: initial;

      &-body {
        padding: 0.5rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .panel {
    &-layout {
      flex-direction: column-reverse;
      height: auto;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    &-wrapper {
      height: auto;
    }

    &-nav {
      justify-content: center;
      flex-direction: row;
      border-right: none;
      border-top: 1px solid $gray-300;

      &-logo {
        display: none !important;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      > div {
        &:first-child {
          flex: 3;
        }

        &:last-child {
          flex: 1;
        }

        flex-direction: row;
        justify-content: space-around;
      }

      a {
        margin: 0;
        padding: 0.4rem 0 0.25rem 0;
      }
    }
  }
}

.menu-item-collapse {
  .nav {
    a {
      padding: 0.3rem;
      margin: 0.2rem;
      font-size: 0.9rem;
    }
  }
}
