.proposed-product {
  .divider {
    display: none;
  }

  span + .divider {
    display: initial;
  }
}

.update-proposed-product {
  .variant-checkboxes {
    margin-top: -10px;
  }
}

.vendor-scrape {
  padding-right: 1rem;
}
