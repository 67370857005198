@import '../../../style/common';

.check-box {
  padding-left: 0;

  .form-check-label {
    display: block;
    cursor: pointer;
    padding: $card-spacer-y $card-spacer-x;
    border: $card-border-width solid $card-border-color;
    border-radius: $card-border-radius;

    &.label-checked {
      border-color: $dark;
    }
  }
}