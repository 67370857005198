.scale-needs-support-user {
  display: block !important;
}

.page-problems {
  .fulfillment-switch .form-group {
    margin-bottom: 0;
  }

  .scale-name {
    color: green;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .scale-problem-details {
    color: #007bff;
    margin-top: 15px;
    margin-bottom: 0px;

    &.comment {
      margin-top: 3px;
      width: 400px;
    }

    .title {
      color: black;
    }
  }
}

.page-summary {
  .separator {
    width: 4px;
    height: 4px;
    background: #ccc;
    border-radius: 50%;
    display: inline-block;
    margin: 3px 0.5rem;
  }

  .rotation-product-name {
    font-size: 0.9rem;

    ~ .text-sm {
      font-size: 0.8rem;
      max-width: 220px;
    }
  }
}
