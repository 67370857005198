.admin-custom-list {
  &-number {
    width: 30px;
  }

  &-button {
    text-align: left;
    text-transform: initial;
  }
}
