@import '~@bottomless/common/style/common';

.page-admin-users {
  .scale__status {
    margin-bottom: 0;
    font-size: 0.9rem;
  }

  kbd {
    font-size: 12px;
  }

  .separator {
    width: 4px;
    height: 4px;
    background: $secondary;
    border-radius: 50%;
    display: inline-block;
    margin: 3px 0.5rem;
  }

  .referral-radio {
    .form-check-input {
      margin-top: 0;
    }
  }
}
