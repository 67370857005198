@import '~@bottomless/common/style/common';

.row-clickable {
  cursor: pointer;
}

.row-strikethrough {
  text-decoration: line-through;
  cursor: not-allowed;
  background: $gray-200;
}

.grey-out {
  cursor: not-allowed;
  background: darkgrey;
}

.subproduct-selection-order {
  .separator {
    width: 4px;
    height: 4px;
    background: $secondary;
    border-radius: 50%;
    display: inline-block;
    margin: 3px 0.5rem;
  }
}

.selected {
  background-color: green;
}
