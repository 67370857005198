@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.placeholder-loading {
  .placeholder-text,
  .placeholder-image {
    position: relative;

    &:after {
      content: '';
      top: 1px;
      bottom: 1px;
      left: -2px;
      right: -2px;
      position: absolute;
      border-radius: 12px;
      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: darkgray;
      background: linear-gradient(to right, #eeeeee 10%, #e5e5e5 18%, #eeeeee 33%);
      background-size: 2600px 104px;
      color: transparent !important;
    }
  }

  .placeholder-image:after {
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
  }
}
