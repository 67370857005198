.page-order-tracking {
  .usps-tracking {
    .usps-status {
      margin-top: 0.5rem;
      text-align: right;
    }
  }

  .tracked-order {
    font-size: 0.9rem;

    .badge {
      font-size: 1rem;
    }
  }
}
