.page-admin-grocery-products {
  .grocery-products-check {
    .form-check-input {
      margin-top: -0.35rem;
    }
  }
  .product-image {
    margin: 0.25rem;
  }
  .description {
    min-width: 350px;
  }
}
